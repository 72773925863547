import "./style.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import OverviewMap from "ol/control/OverviewMap.js";
import DblClickDragZoom from "ol/interaction/DblClickDragZoom.js";
import FullScreen from "ol/control/FullScreen.js";
import { fromLonLat } from "ol/proj.js";
import { defaults as defaultControls } from "ol/control/defaults.js";
import { defaults as defaultInteractions } from "ol/interaction/defaults.js";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import Style from "ol/style/Style.js";
import CircleStyle from "ol/style/Circle.js";
import Fill from "ol/style/Fill.js";
import Stroke from "ol/style/Stroke.js";

const limouLonLat = [10.31672, 50.0198];
const limouPosition = fromLonLat(limouLonLat);
const styles = {
    geoMarker: new Style({
        image: new CircleStyle({
            radius: 10,
            fill: new Fill({ color: "red" }),
            stroke: new Stroke({
                color: "white",
                width: 2,
            }),
        }),
    }),
};

const source = new OSM();

const overviewMapControl = new OverviewMap({
    layers: [
        new TileLayer({
            source: source,
        }),
    ],
});

const map = new Map({
    interactions: defaultInteractions().extend([new DblClickDragZoom()]),
    controls: defaultControls().extend([overviewMapControl]),
    target: "map",
    layers: [
        new TileLayer({
            source: new OSM(),
        }),
    ],
    view: new View({
        center: limouPosition,
        zoom: 14,
    }),
});

const marker = new Feature({
    type: "geoMarker",
    geometry: new Point(limouPosition),
});

const vectorLayer = new VectorLayer({
    source: new VectorSource({
        features: [marker],
    }),
    style: function (feature) {
        return styles[feature.get("type")];
    },
});

map.addLayer(vectorLayer);
